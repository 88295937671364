// src/components/Home.js
import React, {useEffect, useState} from 'react';
import { Container, Typography } from '@mui/material';
import one from '../img/20240518.jpeg';
import two from '../img/MR ROCK STEADY.jpg';
import PhotoAlbum from "react-photo-album";

const Events = () => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const config = {
                    method: 'GET',
                    headers: {
                        "Content-Type": "application/json"
                    }
                };
                const response = await fetch(`https://data.ramsham-hifi.co.uk/events`, config);
                if (!response.ok) {
                    throw new Error(`API Error: ${response.status}`);
                }
                setData(await response.json()); // Assuming the API returns JSON
            } catch (err) {
                setError(err.nessage);
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, []);

    const photos = [
        {
            src: one,
            width: 1200,
            height: 1800,
            srcSet: [
                { src: one, width: 1200, height:  1800}
            ],
        },
        {
            src: two,
            width: 1200,
            height: 1800,
            srcSet: [
                { src: two, width: 1200, height: 1800 }
            ],
        }
        ];

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error: {error}</p>;

    if(!!data)


    return(
        <Container
                   style={{
                       'background': 'rgb(255,255,255,.7)',
                       'padding-top': '20px',
                       'margin-top': '50px'
                   }}>

            <Typography variant="h4" component="h1" gutterBottom>
                Upcoming Events
            </Typography>
            <Typography variant="body1">
                {data.length === 0 ? (
                    <p>No events available at the moment.</p>
                ) : (
                <ul>
                    {data.map((event) => (
                        <li key={event.id}>
                            {event.name} - {event.description}
                        </li>
                    ))}
                </ul>
                )}
            </Typography>

            <br/>

            <Typography variant="h4" component="h1" gutterBottom>
                Past Events
            </Typography>
            <Typography variant="body2">
                    <PhotoAlbum
                        layout="columns"
                        photos={photos}
                        columns={(containerWidth) => {
                            if (containerWidth < 500) return 1;
                            if (containerWidth < 900) return 2;
                            return 2;
                        }}/>
            </Typography>


        </Container>
    );
};

export default Events;
