import React from 'react';
import {Container, Grid} from '@mui/material';

const Mixes = () => {
    return (
        <div id='mixes'>
        <Container
            style={{

                'padding-top': '20px',
            }}
        >

            <Grid container
            spacing={2}>
                <Grid item
                      xs={6}>
                    <iframe title="Ramsham Dub Steppas Mix" width="100%" height="400"
                            src="https://player-widget.mixcloud.com/widget/iframe/?feed=%2Framsham_hifi%2Framsham-hi-fi-dub-steppas-mix%2F"
                            frameBorder="0"></iframe>
                </Grid>
                <Grid item
                      xs={6}>
                    <iframe title="Ramsham April Mix"   width="100%" height="400"
                            src="https://player-widget.mixcloud.com/widget/iframe/?feed=%2Framsham_hifi%2Framsham-hi-fi-april-2023%2F"
                            frameBorder="0"></iframe>
                </Grid>

                <Grid item
                      xs={6}>
                    <iframe title="Ramsham October Mix"  width="100%" height="400"
                            src="https://player-widget.mixcloud.com/widget/iframe/?feed=%2Framsham_hifi%2Framsham-hi-fi-october-2022%2F"
                            frameBorder="0"></iframe>
                </Grid>

                <Grid item
                      xs={6}>
                    <iframe title="Ramsham August Mix"  width="100%" height="400"
                            src="https://player-widget.mixcloud.com/widget/iframe/?feed=%2Framsham_hifi%2Framsham-hi-fi-august-2022%2F"
                            frameBorder="0"></iframe>
                </Grid>
            </Grid>
        </Container>
        </div>
    );
};

export default Mixes;