// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import {AppBar, Toolbar} from '@mui/material';
import Home from './components/Home';
import About from './components/About';
import Contact from './components/Contact';
import Gallery from './components/Photos';
import Event from './components/Events';
import Mixes from "./components/Mixes";
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme';
import HomePageImage from "./img/home.jpeg";
import ToolbarMobileReady from "./components/ToolbarMobileReady";
import Title from "./img/title.png";
import Box from "@mui/material/Box";

function App() {

  var willDisplayAsSinglePage = true;

  if(willDisplayAsSinglePage) {
    let backgroundImageStyle;
    backgroundImageStyle = {
      backgroundImage: `url(${HomePageImage})`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      height: '93vh',
      overflowY: 'auto',
      maxWidth: '100% !important',
      'background-blend-mode': 'lighten',
      'background-color': 'rgba(255,255,255,0.3)'
    };
    return (
        <ThemeProvider theme={theme} margin={0}>
          <AppBar position="static">
            <Toolbar
                style={{
                  display: 'flex',
                  justifyContent: 'space-between', // This ensures the toolbar contents are spaced out
                }}>
              <Box component="img" src={Title} alt="Logo" sx={{ height: 40, mr: 2 }} ></Box>
            </Toolbar>
          </AppBar>
          <div style={backgroundImageStyle}>
            <Home/>
            <Mixes/>
            <Event/>
            <Gallery/>
            <Contact/>
          </div>
        </ThemeProvider>
    );
  } else {
    let backgroundImageStyle;
    backgroundImageStyle = {
      backgroundImage: `url(${HomePageImage})`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      height: '93vh',
      maxWidth: '100% !important',
      'background-blend-mode': 'lighten',
      'background-color': 'rgba(255,255,255,0.3)'
    };
    return (
        <ThemeProvider theme={theme} margin={0}>
          <Router>
            <AppBar position="static">
              <ToolbarMobileReady/>
            </AppBar>
            <div style={backgroundImageStyle}>
              <Routes>
                <Route exact path="/" element={<Home/>}/>
                <Route path="/about" element={<About/>}/>
                <Route path="/mixes" element={<Mixes/>}/>
                <Route path="/contact" element={<Contact/>}/>
                <Route path="/photos" element={<Gallery/>}/>
                <Route path="/events" element={<Event/>}/>
              </Routes>
            </div>
          </Router>
        </ThemeProvider>
    );
  }
}

export default App;
